
html, body {
  display: flex;
  height: auto;
  min-height: 100%;
  width: 100%;
}

#root {
  display: flex;
  width: 100%;
}
